import React, { useContext } from 'react';
import { AppContext } from '../App';
function Key({ keyValue, bigKey }) {
  const { onAlphaSelect, onDelete, onEnter } = useContext(AppContext);
  const chooseAlpha = () => {
    if (keyValue === "ENTER") {
      onEnter();
    }
    else if (keyValue === "DEL") {
      onDelete();
    }
    else {
      onAlphaSelect(keyValue);
    }
  };
  return (
    <div className={`keyClass ${bigKey ? 'bigid' : ''}`} id={'keyid'} onClick={chooseAlpha}>{keyValue}</div>
  )
}
export default Key;

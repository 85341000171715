import React, { useContext } from 'react';
import { AppContext } from '../App';
import Success from './Success';
import Failure from './Failure';
function Gameover() {
  const { gameOver } = useContext(AppContext);
  return (
    <div >
      <div>
        {gameOver.guessedWord ? <Success /> : <Failure />}
      </div>
    </div>
  )
}
export default Gameover;
import React ,{useEffect, useState }from 'react';
function Success() {
  const [isSImageLoaded, setIsSImageLoaded] = useState(false);
  useEffect(() => {
    setIsSImageLoaded(false);
  }, [])
  return (
    <div className='gameOverClass'>
      <img src={'/images/Spic.png'} alt="Abstract graphic indicating game success" onLoad={() => setIsSImageLoaded(true)}/>
      {isSImageLoaded && 
        <>
          <h4>Take a screenshot and <br /> show it to us to collect your reward</h4>
        </>}
    </div>
  )
}
export default Success;
import React from "react";
import { useContext } from "react";
import { AppContext } from "../App";
function Letter({ letterPlace, tryValue }) {
  const { board, rightword, attemptNow } = useContext(AppContext);
  
  const letter = board[tryValue][letterPlace];
  const correct = rightword.toUpperCase()[letterPlace] === letter
  const almost = !correct && letter !== "" && rightword.toUpperCase().includes(letter);
  const guessState = attemptNow.guess > tryValue && (correct ? "correctid" : almost ? "almostid" : "errorid");
  return (
    <div className={`letterClass ${guessState} `} id={'letter-0-0'}>{letter}</div> 
   );}
export default Letter;
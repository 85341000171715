import './App.css';
import Board from './components/Board';
import Keyboard from './components/Keyboard';
import Gameover from './components/Gameover';
import { createContext, useEffect } from 'react';
import { useState } from 'react';
import { ourBoard, generateWordSet } from './Words';
import pycon from './logos/pycon.png';
import logo from './logos/happyfoxlogo.svg';
import onlylogo from './logos/logo.png';
const wordExists = require('word-exists');

export const AppContext = createContext();
function App() {
  var invalid = document.getElementById("invalid");
  var divclass = document.getElementById("boarddiv");
  var rowClassBorder;
  const invalidWord = () => {
    invalid.textContent = "Not in word list! Try a new one.";
    invalid.style.visibility = "visible";
    invalid.style.background = "#424242";
    invalid.style.color = "white";
  }
  var validWord = false;
  const [board, setBoard] = useState(ourBoard);
  const [attemptNow, setAttemptNow] = useState({ guess: 0, position: 0 });
  const [rightword, setRightWord] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [gameOver, setGameOver] = useState({
    gameOver: false, guessedWord: false
  });
  useEffect(() => {
    generateWordSet().then((words) => {
      setRightWord(words.randomWord);
    });
  }, []);
  window.addEventListener('beforeunload', function (event) {
    event.preventDefault();
    event.returnValue = "";
  });
  const onAlphaSelect = (keyValue) => {
    rowClassBorder = "rowID".concat(attemptNow.guess);
    var rowClassElement = document.getElementById(rowClassBorder);
    rowClassElement.style.border = "2px solid #5c5c5c";
    rowClassElement.style.borderRadius = "6px";
    if (attemptNow.position > 4) return;
    const newBoard = [...board];
    newBoard[attemptNow.guess][attemptNow.position] = keyValue;
    setBoard(newBoard);
    setAttemptNow({ ...attemptNow, position: attemptNow.position + 1 });
  };
  const onEnter = () => {
    if (attemptNow.position !== 5) return;
    let currentWord = "";
    for (let i = 0; i < 5; i++) {
      currentWord += board[attemptNow.guess][i];
    }
    if (wordExists(currentWord.toLowerCase())) {

      validWord = true;
      rowClassBorder = "rowID".concat(attemptNow.guess);
      var rowClassElement = document.getElementById(rowClassBorder);
      rowClassElement.style.border = "none";
      setAttemptNow({ guess: attemptNow.guess + 1, position: 0 });
    }
    else {
      validWord = false;
      invalidWord();
    }

    if (currentWord.toLowerCase() === rightword) {
      setGameOver({ gameOver: true, guessedWord: true });
      divclass.style.opacity = "0.1";
      divclass.disabled = true;
      return;
    }
    if (attemptNow.guess === 5 && validWord) {
      setGameOver({ gameOver: true, guessedWord: false });

      divclass.style.opacity = "0.1";
      divclass.disabled = true;

    }
  };
  const onDelete = () => {
    invalid.style.visibility = "hidden";
    if (attemptNow.position === 0) return;
    const newBoard = [...board];
    newBoard[attemptNow.guess][attemptNow.position - 1] = "";
    setBoard(newBoard);
    setAttemptNow({ ...attemptNow, position: attemptNow.position - 1 });
  }
  return (
    <>
      <nav >
        <img id="mobileimage" className="logoClass" src={onlylogo} alt="logo" media="(max-width: 540px)" />
        <img className="laptopimage" src={logo} alt="logo" />
        <span className='title'>Wordle</span>
        <img className='pyconimgClass' src={pycon} alt="logo" />
      </nav>
      <div className='wrapper'>
        <AppContext.Provider value={{
          board, setBoard,
          attemptNow, setAttemptNow, onAlphaSelect, onDelete,
          onEnter, rightword, gameOver, setGameOver, openModal, setOpenModal
        }}>
          <div className="playClass" id="maindiv">
            <span className='titleinplay'>Wordle</span>
            <Board />
            {gameOver.gameOver ? <Gameover /> : <Keyboard />}
          </div>
        </AppContext.Provider>
      </div>
    </>
  );
}
export default App;

import wordBank from "./WordBankfile_1.json";
export const ourBoard = [
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
];

export const generateWordSet = async () => {
    let wordSet;
    let randomWord;
    await fetch(wordBank)
        .then((response) => response.text())
        .then((result) => {
            var obj = wordBank;
            var wordArray = Object.keys(obj).map(function (key) { return obj[key]; });
            randomWord = wordArray[Math.floor(Math.random() * wordArray.length)];
            wordSet = new Set(wordArray);
            });

    return { wordSet, randomWord };
};

import React, { useEffect, useCallback, useContext } from 'react';
import Key from './Key';
import { AppContext } from '../App';
function Keyboard() {
  const { onAlphaSelect, onEnter, onDelete } = useContext(AppContext);
  const alphaset1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
  const alphaset2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
  const alphaset3 = ["Z", "X", "C", "V", "B", "N", "M"];
  const handleKeyTyping = useCallback((event) => {
    if (event.key === "Enter") {
      onEnter();
    } else if (event.key === "Backspace") {
      onDelete();
    }
    else {
      alphaset1.forEach((key) => {
        if (event.key.toLowerCase() === key.toLowerCase()) {
          onAlphaSelect(key);
        }
      });
      alphaset2.forEach((key) => {
        if (event.key.toLowerCase() === key.toLowerCase()) {
          onAlphaSelect(key);
        }
      });
      alphaset3.forEach((key) => {
        if (event.key.toLowerCase() === key.toLowerCase()) {
          onAlphaSelect(key);
        }
      });
    }
  }
  )
  useEffect(() => {
    document.addEventListener("keydown", handleKeyTyping);
    return () => {
      document.removeEventListener("keydown", handleKeyTyping);
    };
  }, [handleKeyTyping]);
  return (
    <div className="keyboardClass" onKeyDown={handleKeyTyping}>
      <div className="keyLine1">
        {alphaset1.map((alpha) => {
          return <Key keyValue={alpha} key={alpha} />
        })}
      </div>
      <div className="keyLine2">
        {alphaset2.map((alpha) => {
          return <Key keyValue={alpha} key={alpha} />
        })}
      </div>
      <div className="keyLine3">
        <Key keyValue={"ENTER"} bigKey  />
        {alphaset3.map((alpha) => {
          return <Key keyValue={alpha} key={alpha} />
        })}
        <Key keyValue={"DEL"} bigKey  />
      </div>
    </div>
  );
}
export default Keyboard;
import React, { useEffect, useState } from 'react'
function Failure() {

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    setIsImageLoaded(false);
  }, [])

  return (
    <>
    <div className='gameOverClass'>
      <img src={'/images/Fpic.png'} alt="Abstract graphic indicating game failure" onLoad={() => setIsImageLoaded(true)}/>
      {isImageLoaded && 
        <>
          <h4>No More Shots</h4>
          <h5 >Wordle Mastery Eludes You.</h5>
        </>}
    </div>
    </>
    
  )
}

export default Failure;
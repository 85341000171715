import React, { useState } from 'react';
import { ourBoard } from '../Words';
import Letter from './Letter';
function Board() {
  const [board, setBoard] = useState(ourBoard);
  return (
    <div className="boardClass" id="boarddiv">
      <h5 id="invalid" className='invalidClass'></h5>
      <div className="rowClass" id="rowID0">
        <Letter letterPlace={0} tryValue={0} />
        <Letter letterPlace={1} tryValue={0} />
        <Letter letterPlace={2} tryValue={0} />
        <Letter letterPlace={3} tryValue={0} />
        <Letter letterPlace={4} tryValue={0} />
      </div>
      <div className="rowClass" id="rowID1">
        <Letter letterPlace={0} tryValue={1} />
        <Letter letterPlace={1} tryValue={1} />
        <Letter letterPlace={2} tryValue={1} />
        <Letter letterPlace={3} tryValue={1} />
        <Letter letterPlace={4} tryValue={1} />
      </div>
      <div className="rowClass" id="rowID2">
        <Letter letterPlace={0} tryValue={2} />
        <Letter letterPlace={1} tryValue={2} />
        <Letter letterPlace={2} tryValue={2} />
        <Letter letterPlace={3} tryValue={2} />
        <Letter letterPlace={4} tryValue={2} />
      </div>
      <div className="rowClass" id="rowID3">
        <Letter letterPlace={0} tryValue={3} />
        <Letter letterPlace={1} tryValue={3} />
        <Letter letterPlace={2} tryValue={3} />
        <Letter letterPlace={3} tryValue={3} />
        <Letter letterPlace={4} tryValue={3} />
      </div>
      <div className="rowClass" id="rowID4">
        <Letter letterPlace={0} tryValue={4} />
        <Letter letterPlace={1} tryValue={4} />
        <Letter letterPlace={2} tryValue={4} />
        <Letter letterPlace={3} tryValue={4} />
        <Letter letterPlace={4} tryValue={4} />
      </div>
      <div className="rowClass" id="rowID5">
        <Letter letterPlace={0} tryValue={5} />
        <Letter letterPlace={1} tryValue={5} />
        <Letter letterPlace={2} tryValue={5} />
        <Letter letterPlace={3} tryValue={5} />
        <Letter letterPlace={4} tryValue={5} />
      </div>
      
    </div>
  )
}
export default Board;